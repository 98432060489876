import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from 'react-bootstrap';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { BsPlusSquareFill } from 'react-icons/bs';
//import Autocomplete from '@mui/joy/Autocomplete';

export default function CustomizedTables() {
  const [showActive, setShowActive] = useState(true);
  const [activeRules, setActiveRules] = useState([]);
  const [inactiveRules, setInactiveRules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredRules, setFilteredRules] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const activeResponse = await fetch('${baseUrl}api/managerrules/getManagerDiscountRules?name=&status=true');
        const activeData = await activeResponse.json();
        if (activeData.status && Array.isArray(activeData.data)) {
          setActiveRules(activeData.data);
        } else {
          throw new Error('Invalid data format');
        }

        const inactiveResponse = await fetch('${baseUrl}api/managerrules/getManagerDiscountRules?name=&status=false');
        const inactiveData = await inactiveResponse.json();
        if (inactiveData.status && Array.isArray(inactiveData.data)) {
          setInactiveRules(inactiveData.data);
        } else {
          throw new Error('Invalid data format');
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Filter rules based on search query
    const filtered = showActive ? activeRules : inactiveRules;
    const regex = new RegExp(searchQuery, 'i');
    const filteredData = filtered.filter(rule => {
      return Object.values(rule).some(value => regex.test(value.toString()));
    });
    setFilteredRules(filteredData);
  }, [searchQuery, showActive, activeRules, inactiveRules]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const tableHeaders = Object.keys(activeRules[0] || {});

  const headersData=[
    "rule_name","fromdate","to_date","discount_type","status"]

  const tableHeadersData=[
    "Rule Name","From Date","To Date","Discount Type","Status"]

  const rulesToShow = showActive ? activeRules : inactiveRules;

  const handleToggle = () => {
    setShowActive(prevShowActive => !prevShowActive);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <React.Fragment>
      <ToggleButtonGroup
        value={showActive ? 'inactive' : 'active'}
        exclusive
        onChange={handleToggle}
        aria-label="Table Toggle"
      >
        <ToggleButton value="active" sx={{ bgcolor: showActive ? 'orange' : 'primary.main' }}>
          Active Rules Table
        </ToggleButton>
        <ToggleButton value="inactive" sx={{ bgcolor: showActive ? 'primary.main' : 'orange' }}>
          Inactive Rules Table
        </ToggleButton>
      </ToggleButtonGroup>

      <div>
        {showActive && (
          <Button style={{ position: 'absolute', top: 10, right: 0 }}>
            <BsPlusSquareFill style={{ marginRight: '5px' }} />
            Create Discount Rule
          </Button>
        )}
      </div>
      
      <div>
        <input type="text" placeholder="Search..." value={searchQuery} onChange={handleSearch} />
      </div>
      
      <div>
        <h3>{showActive ? 'Active Discount Rules' : 'Inactive Discount Rules'}</h3>
       
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeadersData.map(header => {
                  if(header!=="_id") return <TableCell key={header}>{header}</TableCell>
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRules.map((rule, index) => (
                <TableRow key={index}>
                  {headersData.map(header => {
                    if(header!=="_id") return <TableCell key={header}>{rule[header]?.toString()}</TableCell>
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        
      </div>
    </React.Fragment>
  );
}
