// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import SideBar from "../components/Sidebar/SideBar";
// import Dashboard from "../pages/Dashboard";
// import Login from "../pages/Login";
// import { useSelector } from "react-redux";
// import Iframelinks from "../pages/iframelinks";
// import DiscountRules from "../pages/DiscountRules";

// // Happi Admin new file imports
// //cyecom  Admin Ui

// const Approutes = () => {
//   const accessToken = useSelector((state) => state.auth.accessToken);
//   // console.log("accessToken", accessToken);
//   return (
//     <>
//       {accessToken ? (
//         <>
//           <Routes>
//             <Route path="/" element={<Dashboard />} />
//             <Route path="/discount" element={<Iframelinks />} />
//           </Routes>
//         </>
//       ) : (
//         <>
//           <Routes>
//             <Route path="/" element={<Login />} />
//             <Route path="*" element={<> not found</>} />
//           </Routes>
//         </>
//       )}
//     </>
//   );
// };

// export default Approutes;

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SideBar from "../components/Sidebar/SideBar";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import { useSelector } from "react-redux";
import Iframelinks from "../pages/iframelinks";
import DiscountRules from "../pages/DiscountRules";

const Approutes = () => {
  const accessToken = useSelector((state) => state.auth.accessToken);

  return (
    <Routes>
      {accessToken ? (
        <>
          <Route path="/" element={<Dashboard />} />
          <Route path="/discount" element={<Iframelinks />} />
          <Route path="/discount-rules/:id" element={<Iframelinks />} /> {/* New route for DiscountRules */}
        </>
      ) : (
        <>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<div>Not found</div>} />
        </>
      )}
    </Routes>
  );
};

export default Approutes;
